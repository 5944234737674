import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

// Dashboard
const Dashboard = lazy(() => import('../../container/pages/Dashboard'));

// Settings
const Profile = lazy(() => import('../../Mallabiz/Settings/EditProfile'))
const ChangePassword = lazy(() => import('../../Mallabiz/Settings/ChangePassword'))

// Catelouge -> Products
const AllListing = lazy(() => import('../../Mallabiz/Listing/AllListing'));
const CreateListing = lazy(() => import('../../Mallabiz/Listing/CreateListing'));
const ListingDetails = lazy(() => import('../../Mallabiz/Listing/ListingDetails'));

// Catelouge -> Categories
const Categories = lazy(() => import('../../Mallabiz/Categories/Categories'));

// Catelouge -> Attributes
const Attributes = lazy(() => import('../../Mallabiz/Attributes/Attributes'));

// Catelouge -> Coupon Code
const CouponCode = lazy(() => import('../../Mallabiz/CouponCode/CouponCode'));
const CreateCouponCode = lazy(() => import('../../Mallabiz/CouponCode/CreateCouponCode'));
const EditCouponCode = lazy(() => import('../../Mallabiz/CouponCode/EditCouponCode'));

// Orders
const AllOrders = lazy(() => import('../../Mallabiz/Orders/AllOrders'));
const OrderDetails = lazy(() => import('../../Mallabiz/Orders/OrderDetails'));
const EditShippingAddress = lazy(() => import('../../Mallabiz/Orders/EditOrderShipping'));

// Customers / Users
const AllUsers = lazy(() => import('../../Mallabiz/Users/AllUsers/AllUsers'));
const UserInfo = lazy(() => import('../../Mallabiz/Users/UserInfo/UserInfo'));
const AllUserOrders = lazy(() => import('../../Mallabiz/Users/UserInfo/UserOrders'));

// Banners Management / CMS
const MainBanners = lazy(() => import('../../Mallabiz/CMS/BannerManagement/MainBanners/MainBanners'))
const CreateMainBanner = lazy(() => import('../../Mallabiz/CMS/BannerManagement/MainBanners/CreateMainBanner'))
const NewArrivalBanners = lazy(() => import('../../Mallabiz/CMS/BannerManagement/NewArrivalBanners/NewArrivalBanners'))

// Pages / CMS
// const TermsConditions = lazy(() => import('../../Mallabiz/CMS/TermsConditions/TermsConditions'))
const CreateTermsConditions = lazy(() => import('../../Mallabiz/CMS/TermsConditions/CreateTermsConditions'))
const PrivacyPolicy = lazy(() => import('../../Mallabiz/CMS/PrivacyPolicy/PrivacyPolicy'))
const FAQs = lazy(() => import('../../Mallabiz/CMS/FAQs/FAQs'))
const RefundPolicy = lazy(() => import('../../Mallabiz/CMS/RefundPolicy/RefundPolicy'))
const ContactUs = lazy(() => import('../../Mallabiz/CMS/ContactUs/ContactUs'))

// Not Found
// const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>

      {/*Dashboard */}
      <Route path="admin/dashboard" element={<Dashboard />} />

      {/* Settings */}
      <Route path="admin/settings/edit-profile" element={<Profile />}  />
      <Route path="admin/settings/change-password" element={<ChangePassword />}  />

       {/* Categories */}
       <Route path="admin/categories" element={<Categories />} />
      
      {/* Products */}
       <Route path="admin/products/allproducts" element={<AllListing />} />
       <Route path="admin/products/create" element={<CreateListing />} />
       <Route path="admin/products/details" element={<ListingDetails />} />

       {/* Attributes */}
       <Route path="admin/attributes" element={<Attributes />} />

       {/* CouponCode */}
       <Route path="admin/couponcode" element={<CouponCode />} />
       <Route path="admin/couponcode/create" element={<CreateCouponCode />} />
       <Route path="admin/couponcode/edit" element={<EditCouponCode />} />

       {/* Orders */}
       <Route path="admin/orders" element={<AllOrders />} />
       <Route path="admin/orders/details/:id" element={<OrderDetails />} />
       <Route path="admin/orders/shipping-address/edit" element={<EditShippingAddress />} />
      
        {/* Users */}
        <Route path="admin/customers/allcustomers" element={<AllUsers />} />
        <Route path="admin/customers/customer-info/:id" element={<UserInfo />} />
        <Route path="admin/customers/order-info/:id" element={<AllUserOrders />} />

        {/* Banners Management / CMS */}
        <Route path="admin/cms/contactus" element={<ContactUs />} />
        <Route path="admin/cms/mainbanners" element={<MainBanners />} />
        <Route path="admin/cms/mainbanners/create" element={<CreateMainBanner />} />
        <Route path="admin/cms/mainbanners/edit" element={<CreateMainBanner />} />
        <Route path="admin/cms/newarrivalbanners" element={<NewArrivalBanners />} />

        {/* Pages / CMS */}
        <Route path="admin/cms/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="admin/cms/privacypolicy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/privacypolicy/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refundpolicy" element={<RefundPolicy />} />
        <Route path="admin/cms/refundpolicy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refundpolicy/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs" element={<FAQs />} />
        <Route path="admin/cms/faqs/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/edit" element={<CreateTermsConditions />}  />
        {/* <Route path="admin/cms/terms&Conditions" element={<TermsConditions />} /> */}
        {/* <Route path="admin/cms/terms&Conditions/create" element={<CreateTermsConditions />}  /> */}
        {/* <Route path="admin/cms/terms&Conditions/edit" element={<CreateTermsConditions />}  /> */}
        
        {/* Not Found */}
        {/* <Route path="*" element={<NotFound />} /> */}
       <Route path="*" element={<Navigate to="/admin/dashboard" />} />
       
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
