import {
  UilClipboardNotes,
  UilImage,
  UilCreateDashboard,
  UilFileShieldAlt,
  UilSetting,
  UilUsersAlt,
  UilListUiAlt,
  UilEllipsisV,
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import propTypes from "prop-types";
import { NavTitle } from "./Style";
import versions from "../demoData/changelog.json";
import {
  changeDirectionMode,
  changeLayoutMode,
  changeMenuMode,
} from "../redux/themeLayout/actionCreator";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : "";
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector("html");
    if (topMode) {
      html.classList.add("ninjadash-topmenu");
    } else {
      html.classList.remove("ninjadash-topmenu");
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector("html");
      html.setAttribute("dir", "rtl");
    } else {
      const html = document.querySelector("html");
      html.setAttribute("dir", "ltr");
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add("dark-mode");
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove("dark-mode");
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t("dashboard")}
      </NavLink>,
      "dashboard",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      )
    ),

    getItem(t("Catalogue"), "catalogue", !topMenu && <UilListUiAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/products/allproducts`}>
          {t("Products")}
        </NavLink>,
        "allproducts",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/categories`}>
          {t("Categories")}
        </NavLink>,
        "categories",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/attributes`}>
          {t("Attributes")}
        </NavLink>,
        "attributes",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/couponcode`}>
          {t("Coupon Code")}
        </NavLink>,
        "couponcode",
        null
      ),
    ]),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
        {t("Orders")}
      </NavLink>,
      "orders",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/notifications`}>
          <UilClipboardNotes />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/customers/allcustomers`}>
        {t("Customers")}
      </NavLink>,
      "customers",
      !topMenu && (
        <NavLink
          className="menuItem-iocn"
          to={`${path}/customers/allcustomers`}
        >
          <UilUsersAlt />
        </NavLink>
      )
    ),
    getItem(
      t("Banner Management"),
      "bannerManagent",
      !topMenu && <UilImage />,
      [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/cms/mainbanners`}>
            {t("Main Slider Banners")}
          </NavLink>,
          "mainbanners",
          null
        ),
        getItem(
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/cms/newarrivalbanners`}
          >
            {t("New Arrivals")}
          </NavLink>,
          "newarrivals",
          null
        ),
      ]
    ),
    getItem(t("Pages"), "pages", !topMenu && <UilFileShieldAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacypolicy`}>
          {t("Privacy Policy")}
        </NavLink>,
        "privacypolicy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs`}>
          {t("FAQs")}
        </NavLink>,
        "faqs",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/contactus`}>
          {t("Contact Us")}
        </NavLink>,
        "contactus",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/refundpolicy`}>
          {t("Refund & Cancellation Policy")}
        </NavLink>,
        "refundpolicy",
        null
      ),
    ]),
    getItem(t("Settings"), "settings", !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/edit-profile`}>
          {t("Edit Profile")}
        </NavLink>,
        "edit-profile",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/settings/change-password`}
        >
          {t("Change Password")}
        </NavLink>,
        "change-password",
        null
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
