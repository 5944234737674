import { apiHeader } from "./BaseUrl";

// Auth - Login
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      device_type: "web",

    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Logout
export const logoutUser = async () => {
  const access_token = localStorage.getItem('token') || {};
  let data;
  await apiHeader
    .post("/auth/logout", {
      access_token: access_token,
      device_token: "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Forgot Password
export const forgotPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Auth Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/reset", {
      email: values.email,
      forgot_pwd_token: token,
      new_password: values.new_password,
      confirm_new_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Dashboard Card States
export const dashboardStates = async () => {
  let data;
  await apiHeader
    .get("/dashboard")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Dashboard Tab and Orders Tab Get Order
export const getOrders = async (limit, offset, search, orderStatus, orderDate) => {
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;
  if (orderStatus) queryParams += `&status=${orderStatus}`;
  if (orderDate) queryParams += `&order_date=${orderDate}`;
  let data;
  await apiHeader
    .get(`/orders/?${queryParams}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Order Details
export const orderDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/orders/${id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Order Cancel 
export const orderCancel = async (id, values) => {
  let data;
  await apiHeader
   .patch(`/orders/${id}/cancel`,{
    reason: values.cancellation_reason
   })
    .then((res) => {
     data = {
      status: res.status,
      data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Order Status Update
export const orderStatusUpdate = async (id,e) => {
  let data;
  await apiHeader
   .patch(`/orders/${id}/status`, { status: e })
   .then((res) => {
    data = {
      status: res.status,
      data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Order Edit Shipping Detsils
export const orderEditShipping = async (values, id) => {
  let data;
  await apiHeader
   .patch(`/orders/${id}/change-shipping-address`,{
    lat: values.lat,
    lon: values.lon,
    address_title: values.address_title,
    address_line1: values.address_line1,
    address_line2: values.address_line2,
    area: values.area,
    state: values.state
   })
   .then((res) => {
    data = {
      status: res.status,
      data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Customers - Get Customers 
export const getCustomers = async (limit, offset, search) => {
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;
  let data;
  await apiHeader
    .get(`/customers/?${queryParams}`)
    .then((res) => {
      data = {
       status: res.status,
       data: res.data,
       };
     })
     .catch((e) => {
       if (e.response) {
         data = {
           ...e.response.data,
           status: e.response.status,
         };
       }
     });
  return data;
};


// Customer - Customers Update Status 
export const customersStatusUpdate = async (id, active) => {
  let data;
  await apiHeader
    .patch(`/customers/${id}/status`,{
      status: !active
    })
    .then((res) => {
      data = {
       status: res.status,
       data: res.data,
       };
     })
     .catch((e) => {
       if (e.response) {
         data = {
           ...e.response.data,
           status: e.response.status,
         };
       }
     });
  return data;
};


// Customers Tab -> Orders Info 
export const getCusOrderInfo = async (id, limit, offset, search) => {
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;
  let data;
  await apiHeader
    .get(`/customers/${id}/orders/?${queryParams}`)
    .then((res) => {
      data = {
       status: res.status,
       data: res.data,
       };
     })
     .catch((e) => {
       if (e.response) {
         data = {
           ...e.response.data,
           status: e.response.status,
         };
       }
     });
  return data;
};


//Customer Details
export const customerDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/customers/${id}`)
    .then((res) => {
      data = {
       status: res.status,
       data: res.data,
       };
     })
     .catch((e) => {
       if (e.response) {
         data = {
           ...e.response.data,
           status: e.response.status,
         };
       }
     });
  return data;
};


export const cmsGet = async (limit, offset, search,type) => {
  let data;
  let queryParams = `/${type}/?limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;
  await apiHeader
    .get(queryParams)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const cmsCreate = async (type,values) => {
  let data;
  await apiHeader
    .post(`/${type}`, {
      title: values.title,
      description: values.description,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsUpdate = async (type,values,id) => {
  let data;
  await apiHeader
    .put(`/${type}/${id}`,{
      title: values.title,
      description: values.description,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsDelete = async (type,id) => {
  let data;
  await apiHeader
    .delete(`/${type}/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsStatusUpdate = async (type,id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/${type}/${id}/status`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const cmsSwap = async (type,new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/${type}/sort-order`, {
      new_value: new_value,
      new_id: new_id,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const updateBannerImage = async (image,bannerId,type) => {
  let data;
  const apiUrl = `banners/${bannerId}/img`;
  const formData = new FormData();
  formData.append("image", image);
  formData.append("type", type);
  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }
  return data;
};


export const deleteBannerImage = async (id,type,imageName) => {
  let data;
  await apiHeader
    .delete(`banners/${id}/img/${type}/${imageName}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const contactGet = async () => {
  let data;
  await apiHeader
    .get(`/contact-us/`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const contactUpdate = async (values, payload) => {
  let data;
  await apiHeader
    .put("/contact-us/", payload)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Settings - User Profile Get Data 
export const getProfile = async (values) => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Settings - User Profile Change Password
export const changePassword = async (values) => {
  let data;
  await apiHeader
    .put("/auth/password", {
      email: values.email,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Settings - User Profile Edit Data
export const editProfile = async (values) => {
  let data;
  await apiHeader
    .put("/user/profile", {
      first_name: values.first_name,
      last_name: values.last_name,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
      profile_picture: values.image
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
    return data;
};


//Single Image Upload
export const singleImage = async (image) => {
  let data;
  const apiUrl = "/media/single-image-upload";
  const formData = new FormData();
  formData.append("image", image);
  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }
  return data;
};


// Products
export const getAllProducts = async (limit, offset, search, categoryId, filter) => {
  let data;
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&title=${search}`;
  if (categoryId) queryParams += `&category_id=${categoryId}`;
  if (filter) queryParams += `&price=${filter}`;
  await apiHeader
    .get(`/products/?${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const getProductById = async (id) => {
  let data;
  await apiHeader
    .get(`/products/${id}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const createProduct = async (values) => {
  let data;
  await apiHeader
    .post("/products", values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const uploadProductImage = async (id, type, image) => {
  let data;
  const formData = new FormData();
  formData.append("image", image);
  formData.append("type", type);
  await apiHeader
    .post(`/products/${id}/img`, formData)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const getProductCategories = async () => {
  let data;
  await apiHeader
    .get(`/products/categories/list`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const getProductAttributes = async (category_id) => {
  let data;
  await apiHeader
    .get(`/products/${category_id}/attribute`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const getProductAttributeValues = async (attribute_id) => {
  let data;
  await apiHeader
    .get(`/products/${attribute_id}/values`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const getProductTags = async () => {
  let data;
 
  await apiHeader
    .get(`/products/tags/list`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const productStatusUpdate = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/products/${id}/status`, {published: !status})
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const updateProduct = async (id, formData) => {
  let data;
  await apiHeader
    .patch(`/products/${id}`, formData)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// Delete A Product Image i.e cover, product
export const deleteProductImage = async (product_id, type, image_name) => {
  let data;
 
  await apiHeader
    .delete(`/products/${product_id}/img/${type}/${image_name}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// Categories
export const categories = async (limit, offset, search) => {
  let data;
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;
 
  await apiHeader
    .get(`/categories/?${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const getCategoryById = async (id) => {
  let data;
 
  await apiHeader
    .get(`/categories/${id}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const createCategory = async (values) => {
  let data;
  await apiHeader
    .post("/categories", values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const uploadCategoryImage = async (id, image) => {
  let data;
  const formData = new FormData();
  formData.append("image", image);
  await apiHeader
    .post(`/categories/${id}/img`, formData)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const updateCategory = async (id, values) => {
  let data;
  await apiHeader
    .put(`/categories/${id}`, values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const categoriesStatusUpdate = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/categories/${id}/status`, {published: !status})
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// Attributes
export const getAllAttributes = async (limit, offset, search) => {
  let data;
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;
 
  await apiHeader
    .get(`/attributes/?${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const createAttribute = async (values) => {
  let data;
  await apiHeader
    .post("/attributes", values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const updateAttribute = async (id, values) => {
  let data;
  await apiHeader
    .put(`/attributes/${id}`, values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const attributesStatusUpdate = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/attributes/${id}/status`, {published: !status})
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// Coupons
export const getAllCoupons = async (limit, offset, search) => {
  let data;
  let queryParams = `&limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;

  await apiHeader
    .get(`/coupons/?${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const createCoupon = async (values) => {
  let data;
  await apiHeader
    .post("/coupons", values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const updateCoupon = async (id, values) => {
  let data;
  await apiHeader
    .put(`/coupons/${id}`, values)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


export const deleteCoupon = async (id) => {
  let data;
  await apiHeader
    .delete(`/coupons/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Banner Management - Get New Arrivals
export const getNewArrival = async () => {
  let data;
  await apiHeader
    .get("/new-arrivals")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Banner Management - Update New Arrivals
export const updateNewArrival = async (categories_ids) => {
  let data;
  await apiHeader
    .put("/new-arrivals",{
      categories_ids: categories_ids
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};